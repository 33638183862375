import React from 'react';
import PageLayout from '../components/PageLayout';
import {graphql} from 'gatsby';
import {Helmet} from '../components/Helmet';
import {useI18next} from 'gatsby-plugin-react-i18next';

const PrivacyPolicyLT = React.lazy(() => import('../components/privacyPolicy/lt'));
const PrivacyPolicyRU = React.lazy(() => import('../components/privacyPolicy/ru'));
const PrivacyPolicyEN = React.lazy(() => import('../components/privacyPolicy/en'));

const PrivacyPolicyPage = () => {
  const {t, language} = useI18next();

  const renderTerms = () => {
    if (language === 'lt') {
      return <PrivacyPolicyLT/>;
    }

    if (language === 'ru') {
      return <PrivacyPolicyRU/>;
    }

    return <PrivacyPolicyEN/>
  }

  return (
    <PageLayout>
      <main className='container'>
        <Helmet title={t('privacy_policy.title')}/>
        <article className='leading-10 text-primary'>
          {renderTerms()}
        </article>
      </main>
    </PageLayout>
  );
}

export default PrivacyPolicyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["index", "privacy"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
